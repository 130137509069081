
const slide_delay = 3500; // スライド開始時間＠ミリ秒
const slide_duration = 7000; // スライド表示時間＠ミリ秒
const slide_fin_duration = 500; // スライド切替時フェードアウト時間＠ミリ秒

const slide_selector = '#slide'; // スライドセレクタ
const slide_width = 1500; // スライド幅
const slide_height = 1000; // スライド高さ
const slide_min_height = 500; // 最低スライド高さ

const sp_width = 767;

$(function() {
    if($('#slide').css("display") != undefined) {
        
        // スライド処理
        setTimeout(function() {
            
            // 最初のスライドをアクティブ
            $('#slide ul li:first-child').addClass('slide_active');
            
            // スライドが2枚以上なら切替処理
            if($('#slide ul li').length >= 2) {

                var i = 0;

                setInterval(function() {
                    
                    //　現在のスライドの終了処理開始
                    var c = i;
                    $('#slide ul li').eq(c).addClass('slide_fin');
                    
                    i++;
                    
                    // スライドが最後までいったら最初に戻る
                    if(i >= $('#slide ul li').length)
                        i = 0;
                    
                    // スライド終了処理
                    setTimeout(function() {
                        $('#slide ul li').eq(c).removeClass('slide_active');
                        $('#slide ul li').eq(c).removeClass('slide_fin');
                    }, slide_fin_duration);
                    
                    // 次のスライドをアクティブ
                    $('#slide ul li').eq(i).addClass('slide_active');

                }, slide_duration);
            }
        }, slide_delay);
        
        
        slide_display(); // スライド縮尺
        topbanner_display(); // トップバナー
        index_instagram_widget(); // トップInstagramウィジェット
        $(window).on('resize', function() {
            slide_display();
            topbanner_display();
            index_instagram_widget();
        });
        
    }
    
    // pagetop
    if($('#pagetop').css('display') != undefined) {
        $(window).on('scroll', function() {
            if($(window).scrollTop() > $(window).innerHeight()/2) {
                $('#pagetop, #digital_pamphlet').addClass('scroll');
            } else {
                $('#pagetop, #digital_pamphlet').removeClass('scroll');
            }
        
            // 出力していない時は選択不可に
            if(!$('#pagetop').hasClass('scroll')) {
                $('#pagetop').off('selectstart, mousedown')
                .on('selectstart, mousedown', function(){
                    return false;
                });
            } else {
                $('#pagetop').off('selectstart, mousedown');
            }
        
            // 出力していない時は選択不可に
            if(!$('#digital_pamphlet').hasClass('scroll')) {
                $('#digital_pamphlet').off('selectstart, mousedown')
                .on('selectstart, mousedown', function(){
                    return false;
                });
            } else {
                $('#digital_pamphlet').off('selectstart, mousedown');
            }
        });
        if($(document).scrollTop() > $(window).innerHeight())
            $('#pagetop, #digital_pamphlet').addClass('scroll');
        
        $('#pagetop a').click(function() {
            if($(this).parent('nav').hasClass('scroll')) {
                $('body, html').animate({
                    scrollTop: 0
                }, 500);
            }
            return false;
        });
    }
    
    // スマートフォン対応処理
    generate_sp_menu();
    $(window).on('resize', function() {
       generate_sp_menu(); 
    });
    
    // 3領域プラス
    plus_top_contents();
    $(window).on('resize', function() {
       plus_top_contents(); 
    });
});


/**
 *【スライド表示処理】
 *
 *  return null
 **/
function slide_display() {
    
    // 縦横比
    const ratio = slide_height/slide_width;
    var window_width = $(window).width();
    var body_height = $(window).height() - $('body > header').innerHeight();
    
    // ウィンドウ幅変更時
    
    // 新しい高さ
    var new_slide_height = window_width * ratio;

    if(new_slide_height >= slide_min_height && new_slide_height <= slide_height
       && body_height >= new_slide_height // 新しい高さがコンテンツ高さより小さい時
      )
        $(slide_selector).height(new_slide_height);
    
    
    // ウィンドウ高さ変更時
    
    // 新しい縦横比
    var new_ratio = body_height/window_width;
    
    if(body_height >= slide_min_height && body_height <= slide_height
       && window_width > body_height // 幅の方が高さより大きい場合のみ
       && new_ratio <= ratio // 基本の縦横比より高さが低い場合のみ
      )
        $(slide_selector).height(body_height);
    
    
    // スマートフォン用
    if(is_sp()) {
        
        // スライドショー高さ
        $('#slide').height($(window).innerHeight() - ($('body > header').innerHeight() + parseInt($('#slide').css('margin-bottom'))));
        
        if($('#sp_header_nav').css('display') == undefined) {
            $('body > header').after('<div id="sp_header_nav"></div>');
            $('#sp_header_nav').html('<ul class="header_sub_menu">'+$('body > header .header_sub_menu').html()+'</ul>');
        }
        
        // サブメニュー上マージン高さ
        if($('#slide').css('display') != undefined)
            $('#sp_header_nav').css('margin-top', $('#slide').innerHeight());
    
    } else {
        
        $('#slide').height('');
        $('#sp_header_nav').remove();
    
    
        // ウィンドウ高さが初期スライド高さより小さい時
        if(body_height < slide_height)
            $(slide_selector).height(body_height);
    }
}


/**
 *【トップバナー表示処理】
 *
 *  return null
 **/
function topbanner_display() {
    
    if($('#top_banner').css('display') != undefined) {
        if(is_sp()) {
            
            // destroy
            if($('.slick-initialized').css('display') != undefined)
                $('#top_banner').slick('unslick');

        } else {
            
            if($('.slick-initialized').css('display') == undefined) {
                $('#top_banner').slick({
                    'arrows': false,
                    'autoplay': true,
                    'dots': true,
                    'slidesToScroll': 1,
                    'slidesToShow': 4
                });
            }
        }
    }
}


/**
 *【トップInstagramウィジェット処理】
 *
 *  return null
 **/
function index_instagram_widget() {
    
    if($('#index_instagram_widget').css('display') != undefined) {
        if(is_sp()) {
            
            console.log($('.snapwidget-widget-sp').css('display'));
            
            if($('.snapwidget-widget-sp').css('display') == undefined)
                $('#index_instagram_widget').html('<script src="https://snapwidget.com/js/snapwidget.js"></script><iframe src="https://snapwidget.com/embed/1070684" class="snapwidget-widget snapwidget-widget-sp" allowtransparency="true" frameborder="0" scrolling="no" style="border:none; overflow:hidden; width:100%;" title="小石川淑徳学園中学校高等学校Instagram　SP用"></iframe>');

        } else {
        
            if($('.snapwidget-widget-pc').css('display') == undefined)
                $('#index_instagram_widget').html('<script src="https://snapwidget.com/js/snapwidget.js"></script><iframe src="https://snapwidget.com/embed/1070056" class="snapwidget-widget snapwidget-widget-pc" allowtransparency="true" frameborder="0" scrolling="no" style="border:none; overflow:hidden; width:100%;" title="小石川淑徳学園中学校高等学校Instagram　PC用"></iframe>');
        }
    }
}


/**
 *【スマートフォン判別】
 *
 *  return boolean
 **/
function is_sp() {
    if($(window).outerWidth() > sp_width) {
        return false;
    } else {
        return true;
    }
}


/**
 *【スマートフォンメニュー】
 *
 *  return boolean
 **/
function generate_sp_menu() {
    if(is_sp()) {
        
        if($('#sp_menu').css('display') == undefined) {
            
            // HTML生成
            $('body > header > section .header_container:last-of-type').append('<section id="sp_menu"><nav id="sp_menu_opener"><a href="javascript:;"><span></span></a></nav><div id="sp_menu_container" style="display:none;"><nav id="sp_menu_close"><a href="javascript:;"></a></nav></div></section>');
            
            $('#sp_menu_container').append($('body > footer > section > #footer_container .footer_container_wrap:last-of-type nav').html());
            
            // SPメニューオープン処理
            $('#sp_menu_opener a').click(function() {
                $('#sp_menu_container').show();
                $('body').css('overflow', 'hidden');
                setTimeout(function() {
                    $('#sp_menu_container').addClass('sp_menu_open');
                }, 100);
            });
            
            // SPメニュークローズ処理
            $('#sp_menu_close a').click(function() {
                $('#sp_menu_container').removeClass('sp_menu_open');
                $('html, body').css('overflow', '');
                setTimeout(function() {
                    $('#sp_menu_container').hide();
                }, 100);
            });
        }
        
    } else {
        $('#sp_menu').remove();
    }
}


/**
 *【3領域プラストップ処理】
 *
 *  return null
 **/
function plus_top_contents() {
    if($('#areas_plus_container').css('display') != undefined){
        
        // コンテナ高さ
        var container_height = (($('.areas_plus_contents').innerHeight()+20) * 2) + ($('#areas_plus_contents04').innerHeight() + 20 + 80);
        $('#areas_plus_container').height(container_height);
        
        if($('#junior_high_school_container, #high_school_container').css('display') != undefined) {
            // コンテンツ泡高さ
            $('#areas_plus_contents02, #areas_plus_contents03').css('top', $('.areas_plus_contents').innerHeight()+20);

            // コンテンツ最後高さ
            if($('#junior_high_school_container, #high_school_container').hasClass('areas_plus_contents_fin'))
                $('#areas_plus_contents04').css('margin-top', container_height/2 - $('#areas_plus_contents04').innerHeight()/2);

            // アニメーション
            $(document).on('scroll', function() {
                if($(this).scrollTop()+$('body > header').innerHeight() > $('#junior_high_school_container, #high_school_container').offset().top){
                    plus_animation(container_height);
                }
            });
        }
    }
}


/**
 *【3領域プラスアニメーション処理】
 *
 *  return null
 **/
function plus_animation(container_height) {
    
    if($('#junior_high_school_container, #high_school_container').hasClass('areas_plus_contents_fin'))
        return false;
    
    $('#areas_plus_contents04').addClass('areas_plus_contents04_01');
    setTimeout(function() {
        $('#areas_plus_contents04').addClass('areas_plus_contents04_02');
        setTimeout(function() {
            $('#areas_plus_contents04').css('margin-top', container_height/2 - $('#areas_plus_contents04').innerHeight()/2);
            $('#areas_plus_container').addClass('areas_plus_contents_01');

            setTimeout(function() {
                $('#areas_plus_contents01').addClass('areas_plus_contents01_01');
            }, 2500);

            setTimeout(function() {
                $('#areas_plus_contents02').addClass('areas_plus_contents02_01');
            }, 2700);

            setTimeout(function() {
                $('#junior_high_school_container, #high_school_container').addClass('areas_plus_contents_fin');
            }, 2800);

            setTimeout(function() {
                $('#areas_plus_contents03').addClass('areas_plus_contents03_01');
                $('#areas_plus_contents04').removeClass('areas_plus_contents04_02');
            }, 2900);
        }, 500);
    }, 200);
}